<template>
  <div class="reports">
    <div class="card-subtitle mb-3">Отчет Агента</div>
    <div class="my-1">
      <form @submit.stop.prevent="submitForm">
        <div class="row">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <calendar-range @update="onDateChange" label/>
          </div>
          <div class="col-12 col-md-4 col-lg-3 mt-2 mt-md-0 text-right text-md-left">
            <label class="d-none d-md-block">&nbsp;</label>
            <button type="submit" class="btn btn-outline-success" :disabled="errors.start || errors.end">
              Заказать
            </button>
            <a v-if="reportUrl" class="btn btn-outline-info ml-3" :href="reportUrl" target="_blank">
              <i class="bi-download"></i>
              Скачать
            </a>
          </div>
        </div>
      </form>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { Processing } from "@/components/common";
import { apiClientConfig } from "@/configs";
import { createReportManager } from "@/utils/di";
import moment from "moment";
import CalendarRange from "@/components/common/components/CalendarRange.vue";

export default {
  name: "AgentReport",
  components: {
    CalendarRange,
    Processing
  },
  data() {
    return {
      dateParts: {
        start: moment().subtract(1, 'year').format("DD.MM.YYYY").split('.').reduce((acc, val, index) => {
          acc[['day', 'month', 'year'][index]] = val;
          return acc;
        }, {}),
        end: moment().format("DD.MM.YYYY").split('.').reduce((acc, val, index) => {
          acc[['day', 'month', 'year'][index]] = val;
          return acc;
        }, {})
      },
      maxDate: moment().format("YYYY-MM-DD"),
      maxDateFormatted: moment().format("DD.MM.YYYY"),
      errors: {
        start: false,
        end: false
      },
      reportUrl: null,
      processing: false
    };
  },
  methods: {
    onDateChange({ start, end, valid }) {
      this.dateParts = { start, end };
      this.errors = { start: !valid, end: !valid };
    },
    async submitForm() {
      if (this.errors.start || this.errors.end) return;
      this.processing = true;
      this.reportUrl = null;
      try {
        const manager = await createReportManager();
        const urlResult = await manager.exportAgentReport({
          dateFrom: `${this.dateParts.start.year}-${this.dateParts.start.month}-${this.dateParts.start.day}`,
          dateTo: `${this.dateParts.end.year}-${this.dateParts.end.month}-${this.dateParts.end.day}`
        });
        this.reportUrl = apiClientConfig.baseUrl + urlResult.url;
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
